import { Icon } from "@iconify/react";
import { GetServerSideProps } from "next";
import { getSession, signIn } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import Button from "../components/Button";
import Footer from "../components/Footer";
import redirectTo from "../lib/redirectTo";
import GuestLayout from "../components/Layouts/GuestLayout";
import Policies from "./policies";

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getSession(context);
  if (session?.user) return redirectTo("/dashboard");
  return { props: {} };
};

function Hello() {
  return (
    <>
      <header
        className={`
          z-10
          flex
          sticky
          top-0
          justify-between
          px-6
          sm:px-10
          w-full
          h-20
          leading-5
          text-black
          items-center
          bg-white
          border-b-4
          border-teal-600
          border-solid
          box-border
        `}
      >
        <Link href="/hello">
          <a>
            <Image
              src="/images/equal-care-logo.webp"
              alt="Equal Care Co-op"
              title="The Equal Care Co-op Logo"
              className="p-2 h-20"
              height={60}
              width={90}
            />
          </a>
        </Link>
        <button
          onClick={() => signIn("keycloak", { callbackUrl: "/" })}
          className="text-lg flex gap-2 items-center"
        >
          <span>Login</span> <Icon icon="fa6-solid:lock" />
        </button>
      </header>
      <main id="main" className="flex flex-col items-center">
        <section className="max-w-screen-xl py-12 px-4 sm:px-12 lg:p-24 sm:pb-24 grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-24">
          <div className="flex flex-col gap-12 sm:gap-24 sm:justify-center">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              Power in the hands of those who matter most - the people who
              receive and give care and support
            </h2>
            <Link href="/sign-up" passHref>
              <Button
                variant="primary"
                className="text-2xl lg:text-3xl justify-center sm:place-self-start lg:w-72 lg:py-8 lg:px-16 min-w-[16rem]"
              >
                <span>Join</span>
              </Button>
            </Link>
          </div>
          <div className="relative flex items-start min-h-[30rem]">
            <Image
              src="/images/home-illustration.webp"
              alt="Group of people illustration"
              layout="fill"
              objectFit="contain"
            />
          </div>
        </section>
        <div className="bg-hero-background flex justify-center w-full">
          <section className="max-w-screen-xl px-4 sm:px-12 py-12 lg:p-24">
            <h2 className="text-3xl lg:text-4xl font-semibold sm:text-center">
              Find or give trusted, reliable, excellent support
            </h2>
          </section>
        </div>
        <div className="w-full flex justify-center bg-gray-100">
          <section className="px-4 sm:px-12 py-12 lg:p-24 grid grid-cols-1 md:grid-cols-2 gap-12 lg:gap-24">
            <article className="relative rounded-lg bg-white px-6 py-12 lg:p-12">
              <div
                className="h-full grid gap-8"
                style={{ gridTemplateRows: "auto 1fr auto" }}
              >
                <h2 className="text-3xl font-semibold">Find support</h2>
                <div className="text-lg lg:text-xl lg:leading-relaxed">
                  <p>
                    Tell us what&apos;s important to you
                    <br />
                    Choose who supports you
                    <br />
                    Build your own Team
                  </p>
                </div>
                <Link href="/sign-up" passHref>
                  <Button variant="dark" className="sm:w-1/4 md:w-max">
                    Find support
                  </Button>
                </Link>
              </div>
              <div className="absolute right-0 -top-16 transition-all w-1/3 aspect-square">
                <Image
                  src="/images/find-support.webp"
                  alt="Arrow"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </article>
            <article className="relative rounded-lg bg-white px-6 py-12 lg:p-12">
              <div
                className="h-full grid gap-8"
                style={{ gridTemplateRows: "auto 1fr auto" }}
              >
                <h2 className="text-3xl font-semibold">Give support</h2>
                <p className="text-lg lg:text-xl lg:leading-relaxed max-w-xl flex-1">
                  Offer kind, expert care that plays to your strengths,
                  expertise and experience.
                </p>
                <Link href="/sign-up" passHref>
                  <Button variant="dark" className="sm:w-1/4 md:w-max">
                    Give support
                  </Button>
                </Link>
              </div>
              <div className="absolute right-0 -top-16 transition-all w-1/3 aspect-square">
                <Image
                  src="/images/give-support.webp"
                  alt="Arrow"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </article>
          </section>
        </div>
        <section className="bg-white h-12"></section>
      </main>
      <Footer />
    </>
  );
}

Hello.getLayout = GuestLayout;

export default Hello;
